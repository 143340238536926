import { DomainEnvironment } from '../../environment';

export const domainEnvironment: DomainEnvironment = {
    'evernorth.qa.my-wellbeing.com': {
        genesis: {
            baseUrl: 'evernorth.qa.my-wellbeing.com',
            baseApiUrl: 'https://api.qa.personifyhealth.com',
            cookieDomain: 'qa.personifyhealth.com'
        },
        authServer: {
            agentRealm: 'agents',
            baseUrl: 'https://login.qa.personifyhealth.com',
            realmUrl: 'https://login.qa.personifyhealth.com/auth/realms/platform',
            realm: 'platform',
            clientId: 'evernorth-ui'
        },
        euEnrollment: {
            baseUrl: 'https://enroll.evernorth.qa.my-wellbeing.eu'
        },
        naEnrollment: {
            baseUrl: 'https://enroll.evernorth.qa.my-wellbeing.com'
        }
    }
};
