import { DomainEnvironment } from '../../environment';

export const domainEnvironment: DomainEnvironment = {
    'app.qa.personifyhealth.com': {
        genesis: {
            baseUrl: 'app.qa.personifyhealth.com'
        },
        authServer: {
            agentRealm: 'agents',
            baseUrl: 'https://login.qa.personifyhealth.com',
            realmUrl: 'https://login.qa.personifyhealth.com/auth/realms/platform',
            realm: 'platform',
            clientId: 'platform-ui'
        },
        euEnrollment: {
            baseUrl: 'https://enroll.qa.personifyhealth.eu'
        },
        naEnrollment: {
            baseUrl: 'https://enroll.qa.personifyhealth.com'
        }
    },
    'evernorth.qa.my-wellbeing.com': {
        genesis: {
            baseUrl: 'evernorth.qa.my-wellbeing.com'
        },
        authServer: {
            agentRealm: 'agents',
            baseUrl: 'https://login.qa.personifyhealth.com',
            realmUrl: 'https://login.qa.personifyhealth.com/auth/realms/platform',
            realm: 'platform',
            clientId: 'evernorth-ui'
        },
        euEnrollment: {
            baseUrl: 'https://enroll.evernorth.qa.my-wellbeing.eu'
        },
        naEnrollment: {
            baseUrl: 'https://enroll.evernorth.qa.my-wellbeing.com'
        }
    }
};
